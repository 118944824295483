<template>
  <div class="companyAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'单位联系人详情'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-collapse
        v-model="activeNames"
        accordion
      >
        <van-collapse-item
          title="联系人信息"
          name="1"
        >
          <van-cell
            center
            title="单位名称:"
          >
            <template #default>
              <p
                :style="{ color: '#1990ff' }"
                v-if="CompanyInfo.companyName"
                @click="
                  companyClick(CompanyInfo.companyId, CompanyInfo.companyType)
                  "
              >
                {{ CompanyInfo.companyName }}
              </p>
              <p v-else>--</p>
            </template>
          </van-cell>
          <van-cell
            center
            title="状态:"
          >
            <template #default>
              <span
                style="color: #00a854"
                v-if="CompanyInfo.status === 0"
              >有效</span>
              <span
                style="color: #d9001b"
                v-else-if="CompanyInfo.status === 1"
              >无效</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="姓名:"
            :value="CompanyInfo.name || '--'"
          />
          <van-cell
            center
            title="类型:"
            :value="CompanyInfo.type || '--'"
          />
          <van-cell
            center
            title="岗位:"
            :value="CompanyInfo.positionName || '--'"
          />
          <van-cell
            title="联系电话:"
            center
          >
            <template #default>
              <p
                :style="{ color: '#1990ff' }"
                v-if="CompanyInfo.telephone"
                @click="callOut(CompanyInfo)"
              >
                {{ CompanyInfo.telephone }}
              </p>
              <p v-else>--</p>
            </template>
          </van-cell>
          <van-cell
            title="分机号:"
            center
            v-if="CompanyInfo.positionName === '监控电话'"
          >
            <template #default>
              <p
                :style="{ color: '#1990ff' }"
                v-if="CompanyInfo.extNumber"
                @click="callOutExtNumber(CompanyInfo)"
              >
                {{ CompanyInfo.extNumber }}
              </p>
              <p v-else>--</p>
            </template>
          </van-cell>
          <van-cell
            center
            title="联系时段:"
          >
            <template #default>
              <p>
                {{
                  CompanyInfo.contactTimeStart || "--" | qualityTimeFormat
                }}~{{ CompanyInfo.contactTimeEnd || "--" | qualityTimeFormat }}
              </p>
            </template>
          </van-cell>
          <van-cell
            center
            title="更新时间:"
            :value="CompanyInfo.updateDatetime | dateFormat"
          />
          <userJump
            title="更新人:"
            :userList="CompanyInfo.updateBy"
          ></userJump>
        </van-collapse-item>
      </van-collapse>
    </div>
    <van-action-sheet
      description="请选择联系方式"
      v-model="showCallOut"
      :actions="positionActions"
      cancel-text="取消"
      close-on-click-action
      @select="actionSelect"
    />
    <van-action-sheet
      description="请选择联系方式"
      v-model="showCallOutExtNumber"
      :actions="extNumberActions"
      cancel-text="取消"
      close-on-click-action
      @select="extNumberActionSelect"
    />
  </div>
</template>

<script>
import { getContactInfo } from '@/api/contact'
import { check24hour } from '@/api/sendMesage'
import dayjs from 'dayjs'
import { Dialog } from 'vant'
import { mapState } from 'vuex'
import wx from 'weixin-js-sdk'
import index from '@/mixins'

export default {
  name: 'companyAddressInfo',
  mixins: [index],
  data() {
    return {

      activeNames: '1',
      CompanyInfo: {},
      showCallOut: false,
      positionActions: [{ name: '' }, { name: '发送短信' }],
      loadingShow: false,
      extNumberActions: [{ name: '' }],
      showCallOutExtNumber: false
    }
  },
  computed: {
    ...mapState('user', ['jurisdiction'])
  },
  async created() {
    this.loadingShow = true
    try {
      const { data } = await getContactInfo(this.$route.params.id)
      if (data.data) {
        this.CompanyInfo = data.data
        wx.onMenuShareAppMessage({
          title: this.CompanyInfo.companyName, // 分享标题
          desc: `联系人姓名:${this.CompanyInfo.name ? this.CompanyInfo.name : '--'
            }\n类型:${this.CompanyInfo.type ? this.CompanyInfo.type : '--'
            }\n岗位:${this.CompanyInfo.positionName ? this.CompanyInfo.positionName : '--'
            }`, // 分享描述
          link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
          enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          error: function (res) {
            alert('暂无分享权限')
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })
        this.loadingShow = false
      } else {
        this.loadingShow = false
        if (data?.status === 'E00030007') {
          this.$toast.fail({
            message: '暂无相应权限,请联系管理员!',
            duration: 500,
            onOpened: () => {
              this.$router.replace('/')
            }
          })
          return
        }
        this.$toast.fail({
          message: '该联系人已被删除!!',
          duration: 500
        })
      }
    } catch (error) {
      this.loadingShow = false
      if (
        error?.response?.status === 401
      ) {
        return
      }
      if (error.message.includes('timeout')) {
        this.$toast.fail({
          message: '网络不好，请稍后再试!',
          duration: 500
        })
        return
      }
      this.$toast.fail({
        message: '当前网络异常，请稍后再试!!',
        duration: 500
      })
    }
  },
  methods: {
    // 单位跳转
    companyClick(id, type) {
      if (type === 3) {
        if (id && this.jurisdiction.includes('supplier:info')) {
          this.$router.push(`/workTable/supplierAddressInfo/${id}`)
        } else {
          this.$toast.fail({
            message: '暂无相关权限,请联系管理员!',
            duration: 500
          })
        }
      } else {
        if (id) {
          this.$router.push({
            name: 'companyAddressInfo',
            params: {
              Refresh: true,
              id: id
            }
          })
        } else {
          this.$toast.fail({
            message: '暂无相关权限,请联系管理员!',
            duration: 500
          })
        }
      }
    },
    // 呼叫手机
    callOut(val) {
      if (val.status === 1) {
        this.$toast.fail({
          message: '该单位联系人无效!',
          duration: 500
        })
        return
      }
      if (
        !/^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/.test(
          val.telephone
        ) ||
        !this.jurisdiction.includes('contact:sendMessage')
      ) {
        this.positionActions = [this.positionActions[0]]
      }
      this.positionActions[0].name = `呼叫${val.telephone}`
      this.showCallOut = true
    },
    // 呼叫分机号
    callOutExtNumber(val) {
      if (val.status === 1) {
        this.$toast.fail({
          message: '该单位联系人无效!',
          duration: 500
        })
        return
      }
      this.extNumberActions[0].name = `呼叫${val.telephone}-${val.extNumber}`
      this.showCallOutExtNumber = true
    },
    // 联系方式选择
    actionSelect(action, index) {
      if (index === 0) {
        this.callTelephone()
      }
      if (index === 1) {
        this.sendMessage()
      }
    },
    // 分机号联系方式选择
    extNumberActionSelect(action, index) {
      this.callExtNumber()
    },
    // 发送短信
    async sendMessage() {
      try {
        const { data } = await check24hour(this.CompanyInfo.telephone)
        if (data.data.checked) {
          Dialog.confirm({
            title: '确认发送',
            message: '当前单位用户24小时内已确认过短信,是否发送?',
            confirmButtonColor: '#1989FA'
          })
            .then(() => {
              // on close
              this.$router.push(
                `/workTable/sendMessage/${this.CompanyInfo.telephone}/${this.CompanyInfo.name}`
              )
            })
            .catch(() => {
              // on cancel
            })
        } else {
          this.$router.push(
            `/workTable/sendMessage/${this.CompanyInfo.telephone}/${this.CompanyInfo.name}`
          )
        }
      } catch (error) {
        this.$router.push(
          `/workTable/sendMessage/${this.CompanyInfo.telephone}/${this.CompanyInfo.name}`
        )
      }
    },
    // 拨打电话
    callTelephone() {
      if (
        !(
          dayjs(dayjs().format('YYYYMMDD HH:mm')).valueOf() >=
          dayjs(
            `${dayjs().format('YYYYMMDD')} ${dayjs(
              this.CompanyInfo.contactTimeStart
            ).format('HH:mm')}`
          ).valueOf()
        ) ||
        !(
          dayjs(dayjs().format('YYYYMMDD HH:mm')).valueOf() <=
          dayjs(
            `${dayjs().format('YYYYMMDD')} ${dayjs(
              this.CompanyInfo.contactTimeEnd
            ).format('HH:mm')}`
          ).valueOf()
        )
      ) {
        Dialog.confirm({
          title: '确认呼叫',
          message: '当前为非联系时间段,确认呼叫?',
          confirmButtonColor: '#1989FA'
        })
          .then(() => {
            // on close
            window.location.href = `tel:${this.CompanyInfo.telephone}`
          })
          .catch(() => {
            // on cancel
          })
        return
      }
      window.location.href = `tel:${this.CompanyInfo.telephone}`
    },
    // 拨打分机
    callExtNumber() {
      if (
        !(
          dayjs(dayjs().format('YYYYMMDD HH:mm')).valueOf() >=
          dayjs(
            `${dayjs().format('YYYYMMDD')} ${dayjs(
              this.CompanyInfo.contactTimeStart
            ).format('HH:mm')}`
          ).valueOf()
        ) ||
        !(
          dayjs(dayjs().format('YYYYMMDD HH:mm')).valueOf() <=
          dayjs(
            `${dayjs().format('YYYYMMDD')} ${dayjs(
              this.CompanyInfo.contactTimeEnd
            ).format('HH:mm')}`
          ).valueOf()
        )
      ) {
        Dialog.confirm({
          title: '确认呼叫',
          message: '当前为非联系时间段,确认呼叫?',
          confirmButtonColor: '#1989FA'
        })
          .then(() => {
            // on close
            window.location.href = `tel:${this.CompanyInfo.totalTelephone}`
          })
          .catch(() => {
            // on cancel
          })
        return
      }
      window.location.href = `tel:${this.CompanyInfo.totalTelephone}`
    }
  },
  filters: {
    // 格式化质检日期
    qualityTimeFormat(val) {
      if (val === '--') {
        return val
      } else {
        return dayjs(val).format('HH:mm')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.companyAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: auto;

      .van-collapse {
        background-color: transparent;

        .van-collapse-item {
          background-color: transparent;

          .van-collapse-item__title {
            background-color: white;
            font-weight: 700;
          }

          .van-collapse-item__wrapper {
            background-color: transparent;

            .van-collapse-item__content {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
</style>
